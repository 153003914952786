<!--
  PACKAGE_NAME : src\pages\esp\system\code\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 공통코드 목록
-->
<template>
  <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName"/>
</template>

<script>

import EspDxTreeList from "@/components/devextreme/esp-dx-tree-list-v2.vue";

export default {
  components: {
    EspDxTreeList,
  },
  props: {},
  watch: {},
  data() {
    return {
      treeList: {
        refName: 'codeRefName',
        dataSourceDefaultSortColumn: '+codeOrd,+codeNm', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        sortKey: 'codeOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        showColumnLines: true, // 컬럼 라인 표시 여부
        apiActionNm: {
          select: 'CODE_LIST_ALL',
          merge: 'CODE_LIST_MERGE',
          delete: 'CODE_LIST_DELETE',
        },
        showActionButtons: { // 그리드 버튼 노출 설정값
          delete: false, // 삭제 / false가 기본 / 버튼 노출시 멀티 셀렉션 모드로 변경
          sort: true, // 순서 저장 / true가 기본
          toggleExpand: true, // 목록 펼치기/접기 / true가 기본
          customButtons: [],
        },
        filterUseItem: { // 사용중인 항목만 보기 설정 값
          enabled: false, // 사용중인 항목만 보기 / false가 기본
          key: 'delFl', //사용중인 항목만 보기 컬럼
        },
        columns: [
          {
            caption: '코드명',
            dataField: 'codeNm',
            alignment: 'left',
            requiredRule: {},
          },
          {
            caption: '코드값',
            dataField: 'codeValue',
            requiredRule: {},
          },
          {
            caption: '코드키',
            dataField: 'codeKey',
            requiredRule: {},
          },
          {
            caption: '비고',
            dataField: 'description',
          },
          {
            caption: '삭제여부',
            i18n: 'COMPONENTS.DELETE_STATUS',
            dataField: 'delFl',
            lookup: {
              dataSource: this.$_enums.common.stringDelFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ],
      },
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>


